<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle" style="font-size: 18px">{{ list.Title }}</div>
      <div class="rikind">{{ list.IssTime }}</div>
      <div v-if="list.Thumb" class="essayImg">
        <img :src="list.ThumbUrl" alt="" @click="thematic(list.Thumb)" />
      </div>
      <div
        class="essayContent"
        v-html="list.Content"
        @click="imageEnlargement"
      ></div>
      <div class="video" v-if="list.Annex">
        <video style="width: 100%" :src="list.Annex" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <!-- <div class="rikind"
        style="text-align: right;">阅读：{{ list.ReadNum }}</div> -->
      <van-button
        v-if="list.AllowSignup == 1 && this.list.EndTime > this.nowDate"
        class="button"
        @click="Activity"
        round
        type="info"
        >活动申请</van-button
      >
    </div>
  </div>
</template>
<script>
var formatNum = function (num) {
  return num < 10 ? "0" + num : num;
};
import { ImagePreview } from "vant";
import { WxGetWxArticle, WePBEnrolActivity } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
// import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
      pdf: "",
      nowDate: "",
    };
  },
  created() {
    // console.log(this.dataNow);
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    thematic(e) {
      ImagePreview({
        images: [e], // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: true, // 是否显示轮播指示器
        loop: false, // 不设置为false,首图和尾部图不能正常加载
      });
    },
    // 富文本图片内容 点击放大
    imageEnlargement(e) {
      if (e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false, // 不设置为false,首图和尾部图不能正常加载
        });
      }
    },
    // 获取当前时间
    dataNow() {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = formatNum(nowDate.getMonth() + 1);
      var day = formatNum(nowDate.getDate());
      var hour = formatNum(nowDate.getHours());
      var min = formatNum(nowDate.getMinutes());
      var sec = formatNum(nowDate.getSeconds());
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      };
      this.nowDate =
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
    },
    // 获取活动详情
    getRealInfo() {
      this.articleId = this.$route.params.Id;
      WxGetWxArticle({ openID: getOpenId(), articleId: this.articleId }).then(
        (res) => {
          this.list = res.data.data;
          this.list.EndTime = res.data.data.EndTime.replace("T", " ");
        }
      );
    },
    // 报名参加活动
    Activity() {
      this.ActivityFrom.AId = this.aids;
      this.ActivityFrom.OpenID = getOpenId();
      // this.ActivityFrom.OpenID = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      this.ActivityFrom.pAKind = this.list.PAKind;
      WePBEnrolActivity(this.ActivityFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            // on close
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            message: "申请失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
  mounted() {
    this.getRealInfo();
    this.dataNow();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>